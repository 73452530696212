@import 'mixins';
@import 'constants';

.signUpForNewsletter {
  padding: 18.5px 24px 37px;
  border-radius: 8px;
  width: min(100%, 678px) !important;
  margin: 0 auto;
  color: $rove-white;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) 21%,
      rgba(0, 0, 0, 0.6) 52.5%
    ),
    url('/images/newsletter-bg.png');
  background-size: cover;
  background-position: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    position: relative;

    h4 {
      margin: 0;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: left;
      min-width: max-content;
    }
  }

  &__closeButton {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    transition: 0.1s linear all;

    &:hover {
      transform: scale(1.2);
    }
  }

  &__listing {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: $rove-dark-tan;
  }

  &__divider {
    margin: 16px -24px;
    height: 1px;
    width: calc(100% + 48px);
    background: $rove-off-white;
    opacity: 0.2;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }

  &__note {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }

  &__email {
    width: 100%;
    max-width: 420px;

    span {
      font-weight: 500;
      line-height: 1;
      font-size: 14px;
      margin-bottom: 8px;
    }

    input {
      border-radius: 8px;
      border: 1px solid $rove-semi-accent !important;
      outline: none !important;
      background: rgba(255, 255, 255, 0.9) !important;
      padding: 12px 16px !important;
      font-size: 13px !important;
      font-weight: 400 !important;
      line-height: 1 !important;
      color: $rove-darker-tan;
    }
  }

  &__button {
    border-radius: 8px;
    min-height: 44px;
    height: 44px;
    min-width: 213px;
    background-color: $rove-dark-green !important;
    opacity: 1 !important;
    color: $rove-white !important;

    button:not(:disabled) {
      &:hover,
      &:focus {
        background: $rove-black !important;
      }
    }

    button:disabled {
      opacity: 0.7;
    }
  }
}
