@import 'constants';
@import 'mixins';

.container {
  display: flex;
  flex-direction: column;
  margin: 1vh 0;
  font-family: 'Poppins', sans-serif;

  span {
    font-size: 12px;
    margin-bottom: 3px;
  }

  &.roundCorners {
    input {
      border-radius: 100px;
    }
  }

  &.newAuthField {
    width: 100%;
    margin: 0;

    input {
      border-radius: 8px;
      border: 1px solid $rove-border-color;
      background: rgba(255, 255, 255, 0.5);
      padding: 0 15px;
      height: 40px;
      transition: all 0.2s ease;

      &:focus-visible {
        border: 1px solid $rove-dark-black;
      }
    }

    // label
    & > span {
      margin-bottom: 7px;
      color: $rove-dark-black;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
    }
  }

  input {
    border: 1px solid $rove-dark-black;
    padding: 1vh 1vw;
    font-family: 'Poppins', sans-serif;

    @include media('<=phone') {
      width: 100%;
      padding: 1.5vh 3vw;
      background: rgba(255, 255, 255, 0.5);
      border: 0.5px solid $rove-green;

      &::placeholder {
        font-size: 12px;
        line-height: 14px;
        color: $rove-old-tan;
      }
    }

    &:focus-visible {
      outline: 0;
    }
  }

  .error {
    min-height: 20px;

    li {
      color: $rove-red;
      font-weight: 300;
      font-size: 11px;
    }
  }

  &.variantdark {
    margin: 0;

    input {
      border: 1px solid transparent;
      background: rgba(248, 244, 241, 0.1);
      color: $rove-white;

      &::placeholder {
        color: inherit;
      }
    }

    @include media('<=phone') {
      input {
        border: 0.5px solid transparent;

        &::placeholder {
          color: inherit;
        }
      }
    }
  }
}
