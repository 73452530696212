@import 'constants';

.container {
  display: flex;
  height: 50px;
  min-height: 50px;
  border: 2px solid $rove-white;
  border-radius: 100px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.contained {
    background: $rove-off-white;
    color: $rove-black;
  }

  &.containedWithBorder {
    background: $rove-off-white;
    color: $rove-black;
    border: 1px solid $rove-old-tan;
  }

  &.outlined {
    background: transparent;
    color: $rove-white;
  }

  &.darkOutlined {
    background: transparent;
    color: $rove-black;
    border: 1px solid $rove-black;
  }

  &.tanOutlined {
    background: $rove-white;
    border: 1px solid $rove-light-tan;
    color: $rove-light-tan;
    box-shadow: 0 0 4px 0 #00000040;
  }

  &.tanContained {
    background: $rove-light-tan;
    border: 1px solid $rove-light-tan;
    color: $rove-white;
    box-shadow: 0 0 4px 0 #00000040;
  }

  &.darkTanContained {
    background: $rove-darker-tan;
    border: 1px solid $rove-darker-tan;
    color: $rove-white;
    box-shadow: 0 0 4px 0 #00000040;
    border-radius: 8px;
  }

  &.tanOutlinedTransparent {
    background: transparent;
    border: 1px solid $rove-old-tan;
    color: $rove-old-tan;
  }

  &.darkTanOutlined {
    background: transparent;
    border: 1px solid $rove-dark-tan;
    color: $rove-darker-tan;
  }

  &.darkContained {
    background: $rove-black;
    color: $rove-white;
    border: 2px solid $rove-black;
  }

  &.lightContained {
    background: rgba(255, 255, 255, 0.8);
    color: $rove-dark-black;
    border: 2px solid $rove-dark-black;
  }

  &.whiteContained {
    background: $rove-off-white;
    color: $rove-dark-black;
    border: none;
  }

  &.offWhiteContained {
    background: $rove-off-white;
    color: $rove-darker-tan;
    border: 1px solid $rove-light-tan;
    border-radius: 8px;
  }

  &.blueContained {
    background: $rove-blue;
    color: $rove-dark-black;
    border: 1px solid $rove-dark-black;
    font-weight: 300;
  }

  &.newBlueContained {
    background: $rove-selected;
    color: $rove-white;
    border: 1px solid $rove-blue;
  }

  &.darkGreenContained {
    background: $rove-dark-green;
    color: $rove-white;
    border: 1px solid $rove-dark-green;
  }

  &.orangeContained {
    background: $rove-orange;
    color: $rove-black;
    border: 1px solid $rove-dark-black;
  }

  &.greenContained {
    background: $rove-dark-green;
    color: $rove-white;
    border: 1px solid $rove-dark-green;
    transition: 0.2s linear all;
  }

  &.declineContained {
    background: #f8e2da;
    color: $rove-black;
    border: 1px solid $rove-failed;
  }

  &:not(.disabled) {
    &:hover,
    &:focus {
      background: $rove-black;
      color: $rove-white;
    }

    &.darkContained {
      &:hover,
      &:focus {
        background: $rove-off-white;
        color: $rove-black;
      }
    }

    &.tanOutlined,
    &.darkTanOutlined,
    &.tanContained {
      &:hover,
      &:focus {
        background: $rove-white;
        color: $rove-old-tan;
      }
    }

    &.offWhiteContained,
    &.darkTanContained {
      &:hover,
      &:focus {
        background: $rove-white;
        color: $rove-darker-tan;
      }
    }

    &.greenContained {
      &:hover,
      &:focus {
        background: $rove-dark-green;
        border: 1px solid $rove-dark-green;
        opacity: 0.9;
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: 0.2s linear all;
  }

  button {
    width: 100%;
    height: 100%;
    min-height: 100%;
    transition: 0.2s linear all;
    border: none;
    border-radius: inherit;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    font-size: inherit;
    background: inherit;
    color: inherit;
    cursor: inherit;

    &:active {
      opacity: 0.8;
    }
  }
}
