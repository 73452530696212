@import 'constants';
@import 'mixins';

.dialogWrapper {
  width: 699px;
  box-sizing: border-box;
  background-color: $rove-white;
  position: relative;

  @include media('<=tablet') {
    width: calc(100vw - 16px);
    margin: auto;
  }

  .closeButton {
    position: absolute;
    right: 16px;
    top: 16px;
  }
}
